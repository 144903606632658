const domainParameterMapping = {
  'blog.hubspot.com': {
    postCtaKey: 'hubs_post-cta',
  },
  'blog.wthubspot.com': {
    postCtaKey: 'hubs_post-cta',
  },
  '53-staging.sites.hubspot.com': {
    postCtaKey: 'hubs_post-cta',
  },
};

const acceptedDomains = Object.keys(domainParameterMapping);

function appendTrackingParams(attributeName) {
  const { hostname } = window.location;

  if (acceptedDomains.includes(hostname)) {
    const { postCtaKey } = domainParameterMapping[hostname];
    document.querySelectorAll(`[${attributeName}]`).forEach(element => {
      const trackingParams = element.getAttribute(attributeName);

      const elementHrefUrl = new URL(element.href);
      elementHrefUrl.searchParams.set(postCtaKey, trackingParams);

      element.href = elementHrefUrl.toString();
    });
  }
}

export default appendTrackingParams;
