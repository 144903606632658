/**
 * @author Matt Staff <mstaff@hubspot.com>
 * @copyright HubSpot, Inc (c) 2021
 */
import { getCurrentUrl, findUrlInList } from './utils';

const SIGNUP_TYPE = 'signup';
const CONTENT_TYPE = 'content';
const OFFERS_TYPE = 'offers';
const POST_TYPE = 'post';

/**
 * @type {Object.<string, HubsParamsConfig>}
 * @todo Add QA Config values for wthubspot.com domain
 */
const wtParamsConfig = {
  'hubspot.de': {
    destination: {
      [SIGNUP_TYPE]: [
        'academy.hubspot.de',
        'app.hubspot.com/signup',
        'app.hubspot.com/login',
        'offers.hubspot.de',
        'www.hubspot.de/products/cms/demo',
        'blog.hubspot.de/cs/c/',
        'cta-redirect.hubspot.com',
      ],
      [CONTENT_TYPE]: [
        'www.hubspot.de/products',
        'www.hubspot.de/pricing',
      ],
      [OFFERS_TYPE]: [
        'offers.hubspot.de/danke',
      ],
      [POST_TYPE]: [
        'app.hubspot.com',
        'knowledge.hubspot.com',
        'offers.hubspot.de',
        'www.hubspot.de',
        'blog.hubspot.de/cs/c/',
        'cta-redirect.hubspot.com',
      ],
    },
    domain: {
      [SIGNUP_TYPE]: [
        'academy.hubspot.de',
        'offers.hubspot.de',
        'www.hubspot.de',
        'blog.hubspot.de'
      ],
      [CONTENT_TYPE]: [
        'academy.hubspot.de',
        'offers.hubspot.de',
        'www.hubspot.de',
      ],
      [OFFERS_TYPE]: ['offers.hubspot.de'],
      [POST_TYPE]: ['blog.hubspot.de'],
    },
  },
  'hubspot.com': {
    destination: {
      [SIGNUP_TYPE]: [
        'app.hubspot.com/signup',
        'app.hubspot.com/login',
        'offers.hubspot.com',
        'www.hubspot.com/products/cms/demo',
        'app.hubspot.com/signup/crm',
        'app.hubspot.com/l',
        'blog.hubspot.com/cs/c/',
        'cta-redirect.hubspot.com',
      ],
      [CONTENT_TYPE]: [
        'www.hubspot.com/products',
        'www.hubspot.com/pricing',
        'www.hubspot.com/comparisons',
      ],
      [OFFERS_TYPE]: [
        'offers.hubspot.com/thank-you',
      ],
      [POST_TYPE]: [
        'app.hubspot.com',
        'knowledge.hubspot.com',
        'offers.hubspot.com',
        'www.hubspot.com',
        'blog.hubspot.com/cs/c/',
        'cta-redirect.hubspot.com',
      ],
    },
    domain: {
      [SIGNUP_TYPE]: [
        'academy.hubspot.com',
        'offers.hubspot.com',
        'knowledge.hubspot.com',
        'www.hubspot.com',
        'ecosystem.hubspot.com',
        '53-staging.sites.hubspot.com',
        'blog.hubspot.com',
      ],
      [CONTENT_TYPE]: [
        'academy.hubspot.com',
        'community.hubspot.com',
        'help.hubspot.com',
        'knowledge.hubspot.com',
        'offers.hubspot.com',
        'www.hubspot.com',
        'ecosystem.hubspot.com',
      ],
      [OFFERS_TYPE]: ['offers.hubspot.com'],
      [POST_TYPE]: ['blog.hubspot.com', '53-staging.sites.hubspot.com'],
    },
  },
  'hubspot.es': {
    destination: {
      [SIGNUP_TYPE]: [
        'offers.hubspot.es',
        'app.hubspot.com/signup',
        'app.hubspot.com/login',
        'offers.hubspot.es',
        'www.hubspot.es/products/cms/demo',
        'blog.hubspot.es/cs/c/',
        'cta-redirect.hubspot.com',
      ],
      [CONTENT_TYPE]: [
        'www.hubspot.es/products',
        'www.hubspot.es/pricing',
      ],
      [OFFERS_TYPE]: [
        'offers.hubspot.es/muchas-gracias',
      ],
      [POST_TYPE]: [
        'app.hubspot.com',
        'knowledge.hubspot.com',
        'offers.hubspot.es',
        'www.hubspot.es',
        'blog.hubspot.es/cs/c/',
        'cta-redirect.hubspot.com',
      ],
    },
    domain: {
      [SIGNUP_TYPE]: [
        'academy.hubspot.es',
        'offers.hubspot.es',
        'www.hubspot.es',
        'blog.hubspot.es'
      ],
      [CONTENT_TYPE]: [
        'academy.hubspot.es',
        'offers.hubspot.es',
        'www.hubspot.es',
      ],
      [OFFERS_TYPE]: ['offers.hubspot.es'],
      [POST_TYPE]: ['blog.hubspot.es'],
    },
  },
  'hubspot.fr': {
    destination: {
      [SIGNUP_TYPE]: [
        'academy.hubspot.fr',
        'app.hubspot.com/signup',
        'app.hubspot.com/login',
        'offers.hubspot.fr',
        'hubspot.fr/products/cms/demo',
        'blog.hubspot.fr/cs/c/',
        'cta-redirect.hubspot.com',
      ],
      [CONTENT_TYPE]: [
        'www.hubspot.fr/products',
        'www.hubspot.fr/pricing'
      ],
      [OFFERS_TYPE]: [
        'offers.hubspot.fr/merci'
      ],
      [POST_TYPE]: [
        'app.hubspot.com',
        'knowledge.hubspot.com',
        'offers.hubspot.fr',
        'www.hubspot.fr',
        'blog.hubspot.fr/cs/c/',
        'cta-redirect.hubspot.com',
      ],
    },
    domain: {
      [SIGNUP_TYPE]: [
        'academy.hubspot.fr',
        'offers.hubspot.fr',
        'www.hubspot.fr',
        'blog.hubspot.fr'
      ],
      [CONTENT_TYPE]: [
        'academy.hubspot.fr',
        'offers.hubspot.fr',
        'www.hubspot.fr',
      ],
      [OFFERS_TYPE]: ['offers.hubspot.fr'],
      [POST_TYPE]: ['blog.hubspot.fr'],
    },
  },
  'hubspot.jp': {
    destination: {
      [SIGNUP_TYPE]: [
        'academy.hubspot.jp',
        'app.hubspot.com/signup',
        'app.hubspot.com/login',
        'offers.hubspot.jp',
        'hubspot.jp/products/cms/demo',
        'blog.hubspot.jp/cs/c/',
        'cta-redirect.hubspot.com',
      ],
      [CONTENT_TYPE]: [
        'www.hubspot.jp/products',
        'www.hubspot.jp/pricing'
      ],
      [OFFERS_TYPE]: [
        'offers.hubspot.jp/thank-you'
      ],
      [POST_TYPE]: [
        'app.hubspot.com',
        'knowledge.hubspot.com',
        'offers.hubspot.jp',
        'www.hubspot.jp',
        'blog.hubspot.jp/cs/c/',
        'cta-redirect.hubspot.com',
      ],
    },
    domain: {
      [SIGNUP_TYPE]: [
        'academy.hubspot.jp',
        'offers.hubspot.jp',
        'www.hubspot.jp',
        'blog.hubspot.jp'
      ],
      [CONTENT_TYPE]: [
        'academy.hubspot.jp',
        'offers.hubspot.jp',
        'www.hubspot.jp',
      ],
      [OFFERS_TYPE]: ['offers.hubspot.jp'],
      [POST_TYPE]: ['blog.hubspot.jp'],
    },
  },
  'br.hubspot.com': {
    destination: {
      [SIGNUP_TYPE]: [
        'app.hubspot.com/signup',
        'app.hubspot.com/login',
        'br.hubspot.com/ofertas',
        'br.hubspot.com/products/cms/demo',
        'br.hubspot.com/blog/cs/c/',
        'cta-redirect.hubspot.com',
      ],
      [CONTENT_TYPE]: [
        'br.hubspot.com/products',
        'br.hubspot.com/pricing'
      ],
      [OFFERS_TYPE]: [
        'br.hubspot.com/ofertas/obrigado'
      ],
      [POST_TYPE]: [
        'app.hubspot.com',
        'knowledge.hubspot.com',
        'br.hubspot.com/ofertas',
        'br.hubspot.com/products',
        'br.hubspot.com/pricing',
        'br.hubspot.com/resources',
        'br.hubspot.com/inbound-marketing',
        'br.hubspot.com/why-go-hubspot',
        'br.hubspot.com/services',
        'br.hubspot.com/partners',
        'br.hubspot.com/blog/cs/c/',
        'cta-redirect.hubspot.com',
      ],
    },
    domain: {
      [SIGNUP_TYPE]: [
        'br.hubspot.com/products',
        'br.hubspot.com/ofertas',
        'br.hubspot.com/pricing',
        'br.hubspot.com/blog'
      ],
      [CONTENT_TYPE]: ['br.hubspot.com(?!/blog|/ofertas)'],
      [OFFERS_TYPE]: ['br.hubspot.com/ofertas'],
      [POST_TYPE]: ['br.hubspot.com/blog'],
    },
  },
};

/**
 * @typedef {Object} HubsParamsConfig
 * @property {Object} destination signup dest list
 * @property {string[]} destination.signup signup dest list
 * @property {string[]} destination.content signup dest list
 * @property {string[]} destination.offers signup dest list
 * @property {string[]} destination.post signup dest list
 * @property {Object} domain signup domain list
 * @property {string[]} domain.signup signup domain list
 * @property {string[]} domain.content signup domain list
 * @property {string[]} domain.offers signup domain list
 * @property {string[]} domain.post signup domain list
 */

/**
 * @throws
 * @returns {HubsParamsConfig}
 */
function getConfig() {
  /** @type {undefined|HubsParamsConfig} */
  let config;
  const { hostname } = window.location;
  const isBr = hostname.indexOf('br.hubspot.com') > -1;
  const isStaging = hostname.indexOf('53-staging.sites.hubspot.com') > -1;
  const isLocalhost =
    hostname.indexOf('localhost') > -1 ||
    hostname.indexOf('local.hubspot.com') > -1;

  if (isLocalhost || isStaging) {
    config = wtParamsConfig['hubspot.com'];
  } else if (isBr) {
    config = wtParamsConfig[hostname];
  } else {
    let domain = hostname.slice(hostname.indexOf('.') + 1);
    const isQA = domain.indexOf('qa') > -1;

    if (isQA) {
      domain = domain.replace('qa', '');
    }

    config = wtParamsConfig[domain];
  }

  if (!config) throw new RangeError('Domain out of Range for Config');
  return config;
}

function getCurrentDomains(prop) {
  const config = getConfig();
  const { domain } = config;
  return domain[prop] && domain[prop].length ? domain[prop] : [];
}

function getDestinationDomains(prop) {
  const config = getConfig();
  const { destination } = config;
  return destination[prop] && destination[prop].length ? destination[prop] : [];
}

/**
 * @typedef {Object} DestinationList
 * @property {string[]} signupList
 * @property {string[]} contentList
 * @property {string[]} offersList
 * @property {string[]} postList
 *
 * @returns {DestinationList}
 */
export function getDestinationList() {
  const signupList = getDestinationDomains('signup');
  const contentList = getDestinationDomains('content');
  const offersList = getDestinationDomains('offers');
  const postList = getDestinationDomains('post');

  return {
    signupList,
    contentList,
    offersList,
    postList,
  };
}

/**
 * @typedef {Object} DomainList
 * @property {string[]} inSignupDomain
 * @property {string[]} inContentDomain
 * @property {string[]} inOffersDomain
 * @property {string[]} inPostDomain
 *
 * @returns {DomainList}
 */
export function getIsInDomainList() {
  const signupDomains = getCurrentDomains(SIGNUP_TYPE);
  const contentDomains = getCurrentDomains(CONTENT_TYPE);
  const offersDomains = getCurrentDomains(OFFERS_TYPE);
  const postDomains = getCurrentDomains(POST_TYPE);

  // Check currentUrl matches rollout domain list
  const currentUrl = getCurrentUrl();
  const inSignupDomain = findUrlInList(currentUrl, signupDomains);
  const inContentDomain = findUrlInList(currentUrl, contentDomains);
  const inOffersDomain = findUrlInList(currentUrl, offersDomains);
  const inPostDomain = findUrlInList(currentUrl, postDomains);

  return {
    inSignupDomain,
    inContentDomain,
    inOffersDomain,
    inPostDomain,
  };
}


