/**
 * @namespace UrlParamPassthru (UPP)
 * @description Page Query Params module a.k.a "Url Param Passthru" (UPP)
 */

import { filterProductParams } from './utils';

/**
 * @function getQueryParamsList
 * @memberof UrlParamPassthru
 * @returns {string[]} a list of url params
 */
function getQueryParamsList() {
  const searchString = window.location.search.slice(1);
  if (!searchString) return [];
  const paramsList = searchString.split('&');
  return filterProductParams(paramsList);
}

/**
 *
 * @param {HTMLAnchorElement} item
 * @param {string[]} pageParamsList
 */
function appendNewParams(item, pageParamsList) {
  const url = new URL(item.href);

  pageParamsList.map(paramString => {
    if (!paramString || paramString === '=') return; // Skip empty values
    const chunks = paramString.split('=');
    const key = chunks[0];
    const value = chunks[1];
    if (!url.searchParams.has(key)) {
      url.searchParams.append(key, value);
    }
  });

  return url.toString();
}

/**
 * @function propagateParams
 * @memberof UrlParamPassthru
 * @see getQueryParamsList
 * @see appendNewParams
 */
function mainPageQueryParams() {
  const filteredParamsList = getQueryParamsList();
  if (!filteredParamsList || !filteredParamsList.length) return;

  document
    .querySelectorAll('a[href]:not([href*="#"])')
    .forEach(function (item) {
      const newUrl = appendNewParams(item, filteredParamsList);
      item.href = newUrl;
    });
}

export default mainPageQueryParams;
