/* eslint-disable import/prefer-default-export */
/* eslint-disable no-underscore-dangle */
/**
 * @version 1.17.9
 * @license TBD
 * @copyright HubSpot, Inc. (c) 2021
 */
import { name, version } from '../package.json';
import main from './app.js';
import appendHubsParams from './hubs-params';

try {
  setTimeout(main, 350);
} catch (e) {
  console.error(`Caught ${name}@${version} Error:`, e);
}

export const _appendHubsParams = appendHubsParams;
