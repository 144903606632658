/**
 * @author Matt Staff <mstaff@hubspot.com>
 * @copyright HubSpot, Inc (c) 2021
 */

import { getDestinationList, getIsInDomainList } from './hubs-params-config';
import {
  addParamsToUrl,
  findUrlInList,
  getCurrentUrl,
  isMissingUrlParam,
  removeProtocol,
  isOverrideException,
  getCurrentValueFromUrl,
} from './utils';

/**
 * Retrieves the HubSpot generated CTA wrapping element
 * @param {HTMLElement }element
 * @returns HS CTA wrapper if it exists, otherwise null
 */
function getHubSpotGeneratedCta(element) {
  return element.closest('.hs-cta-wrapper');
}

/**
 * Checks if a Hubspot generated CTA is fully rendered by checking the nodes ID prefix
 * @param {HTMLElement} element
 * @returns true if the cta is fully rendered, otherwise false
 */
function checkIfHubspotGeneratedCtaIsRendered(element) {
  const idPrefix = 'cta_button_53';

  if (element.nodeName === 'A') {
    return element.id.includes(idPrefix);
  }

  const hubspotGeneratedCTA = getHubSpotGeneratedCta(element);

  if (hubspotGeneratedCTA) {
    const anchor = element.querySelector('a');

    return anchor.id.includes(idPrefix);
  }
}

/**
 * Adds a specified paramater to HS generated CTA if the parameter was not set in the CTA tool
 * @param {HTMLAnchorElement} anchor - <a> node contained within the HS generated CTA
 * @param {string} parameter - parameter to add to anchor href if it doesn't exist
 * @returns void
 */
 function addParamToHrefIfNotInCtaDestLink(anchor, parameter, overrideExceptions) {
  const ctaDestLinkAttribute = anchor.getAttribute('cta_dest_link');

  if (ctaDestLinkAttribute) {
    const setInCmsCtaLink = new URL(ctaDestLinkAttribute);
    const setInCmsCtaParams = new URLSearchParams(setInCmsCtaLink.search);
    let paramValue = setInCmsCtaParams.get(parameter);
    if (!paramValue) {
      paramValue = getCtaParamValue(anchor, parameter, overrideExceptions);
      anchor.href = addParamsToUrl(anchor.href, parameter, paramValue);
    }
  }
}

function updateUrlParam({
  urlParam,
  element,
  forceParamUpdate = false,
  overrideExceptions = [],
}) {
  if (isMissingUrlParam(element.href, urlParam) || forceParamUpdate) {
    let currentUrl = removeProtocol(getCurrentUrl().split('?')[0]);

    if (isOverrideException(element?.id, urlParam, overrideExceptions)) {
      currentUrl = getCurrentValueFromUrl(element.href, urlParam);
    }

    const hubspotGeneratedCTA = getHubSpotGeneratedCta(element);

    if (hubspotGeneratedCTA) {
      const isCtaFullyRendered = checkIfHubspotGeneratedCtaIsRendered(element);

      if (isCtaFullyRendered) {
        element.href = addParamsToUrl(element.href, urlParam, currentUrl);
      } else {
        const observer = new MutationObserver((mutations, self) => {
          const anchor = hubspotGeneratedCTA.querySelector('a');
          const isCtaFullyRendered = checkIfHubspotGeneratedCtaIsRendered(anchor);

          if (isCtaFullyRendered) {
            anchor.href = addParamsToUrl(anchor.href, urlParam, currentUrl);
            self.disconnect();
          }
        });

        observer.observe(hubspotGeneratedCTA, { attributes: true, childList: true, subtree: true });
      }
    } else {
      element.href = addParamsToUrl(element.href, urlParam, currentUrl);
    }
  }
}

function getCtaParamValue(element, parameter, overrideExceptions = []) {
  let paramValue;
  const ctaText = element.innerText;
  const ctaId = element.id;
  const ctaClass = element.classList.item(element.classList.length - 1);
  const invalidClassNames = [
    null,
    'cta--small',
    'cta--medium',
    'cta--large',
    'cta--primary',
    'cta--secondary',
    'cta--blue',
    'cta--primary-light',
    'cl-button',
    '-primary',
    '-regular',
    '-small',
    '-large',
    'cl-navLink',
    'ga_nav_link'
  ];
  const validClassName = invalidClassNames.indexOf(ctaClass) === -1;

  if (ctaClass && validClassName) {
    paramValue = ctaClass;
  } else {
    paramValue = ctaText || ctaId || null;
  }

  if (isOverrideException(element?.id, parameter, overrideExceptions)) {
    paramValue = getCurrentValueFromUrl(element.href, parameter);
  }

  return paramValue;
}

function updateCtaParam({
  ctaParam,
  element,
  forceParamUpdate = false,
  overrideExceptions = [],
}) {
  if (isMissingUrlParam(element.href, ctaParam) || forceParamUpdate) {
    const hubspotGeneratedCTA = getHubSpotGeneratedCta(element);

    if (hubspotGeneratedCTA) {
      const isCtaFullyRendered = checkIfHubspotGeneratedCtaIsRendered(element);

      if (isCtaFullyRendered) {
        addParamToHrefIfNotInCtaDestLink(element, ctaParam, overrideExceptions);
      } else {
        const observer = new MutationObserver((mutations, self) => {
          const anchor = hubspotGeneratedCTA.querySelector('a');
          const isCtaFullyRendered = checkIfHubspotGeneratedCtaIsRendered(anchor);

          if (isCtaFullyRendered) {
            addParamToHrefIfNotInCtaDestLink(anchor, ctaParam, overrideExceptions);
            self.disconnect();
          }
        });

        observer.observe(hubspotGeneratedCTA, { attributes: true, childList: true, subtree: true });
      }
    } else {
      element.href = addParamsToUrl(element.href, ctaParam, getCtaParamValue(element, ctaParam, overrideExceptions));
    }
  }
}

function addParams(options) {
  updateUrlParam(options);

  if (options?.ctaParam) {
    updateCtaParam(options);
  }
}

/**
 * @param {HTMLAnchorElement} element
 * @param {DestinationList} destinationList
 * @param {DomainList} domainList
 * @returns {HTMLAnchorElement|null} updated element or null
 */
function appendParamsToElement({
  element,
  destinationList,
  domainList,
  ...rest
}) {
  const hostPath = element?.hostname + element?.pathname;

  if (hostPath === getCurrentUrl()) {
    return null;
  }

  const isCmsDemoCTA = hostPath.indexOf('products/cms/demo') > -1;
  const isOpsDemoCTA = hostPath.indexOf('products/operations/demo') > -1;
  const { signupList, contentList, offersList, postList } = destinationList;

  const { inSignupDomain, inContentDomain, inOffersDomain, inPostDomain } =
    domainList;

  // Check href matches dest url list
  const inSignupList = findUrlInList(hostPath, signupList);
  const inContentList =
    findUrlInList(hostPath, contentList) && !isCmsDemoCTA && !isOpsDemoCTA;
  const inOffersList = findUrlInList(hostPath, offersList);
  const inPostList = findUrlInList(hostPath, postList);

  if (inSignupList && inSignupDomain) {
    addParams({
      element,
      urlParam: 'hubs_signup-url',
      ctaParam: 'hubs_signup-cta',
      ...rest,
    });
  }

  if (inContentList && inContentDomain) {
    addParams({
      element,
      urlParam: 'hubs_content',
      ctaParam: 'hubs_content-cta',
      ...rest,
    });
  }

  if (inOffersList && inOffersDomain) {
    addParams({ element, urlParam: 'hubs_offer', ...rest });
  }

  if (inPostList && inPostDomain) {
    addParams({
      element,
      urlParam: 'hubs_post',
      ctaParam: 'hubs_post-cta',
      ...rest,
    });
  }

  if (inSignupList || inContentList || inOffersList || inPostList) {
    return element;
  }

  return null;
}

/**
 * @function appendHubsParams
 * @returns {HTMLAnchorElement[]}
 */
export default function appendHubsParams(options) {
  const { links } = document;
  const updatedTags = []; // list of all updated params

  const destinationList = getDestinationList();
  const domainList = getIsInDomainList();

  // iterate over links & update any links that qualify
  for (let i = 0, l = links.length; i < l; i++) {
    const element = links[i];

    if (element.dataset.hubs_params === 'false') continue;

    const updatedEl = appendParamsToElement({
      forceParamUpdate: options?.forceParamUpdate,
      overrideExceptions: options?.overrideExceptions,
      element,
      destinationList,
      domainList,
    });
    updatedTags.push(updatedEl);
  }

  return updatedTags;
}

/**
 *
 * @param url
 * @param text
 * @return {HTMLAnchorElement|string|null}
 */
export function reactHubsParam(url, text) {
  const destinationList = getDestinationList();
  const domainList = getIsInDomainList();

  const element = document.createElement('a');
  element.href = url;
  element.innerText = text;

  appendParamsToElement({ element, destinationList, domainList });
  return element.href;
}
