/**
 * @copyright HubSpot, Inc (c) 2021
 * script utility methods
 */

function identity(item) {
  return item;
}

function mapHTMLCollection(collection, callback) {
  const nodeArray = [];
  for (let i = 0; i < collection.length; i++) {
    const item = collection.item(i);
    const value = callback(item);
    nodeArray.push(value);
  }
  return nodeArray;
}

function htmlCollectionToArray(collection) {
  return mapHTMLCollection(collection, identity);
}

/**
 *
 * @param {string} Value
 * Value to encode can be one of the following:
 * url with protocol removed ((http|https)://)
 * cta classname
 * cta innerText
 */
function encodeParamValue(value) {
  // @todo consider decoding values before re-encoding
  const encodedValue = encodeURIComponent(value);
  // do not encode / to %2F
  const partiallyDecodedValue = encodedValue.replace(/%2F/g, '/');
  return partiallyDecodedValue;
}

/**
 *
 * @param {string} url href of tag
 * @param {string} key url param name
 * @param {string} value value of url param
 */
function addParamsToUrl(url, key, rawValue, encode = true) {
  const value = encode ? encodeParamValue(rawValue) : rawValue;
  const re = new RegExp(`([?&])${key}=.*?(&|#|$)(.*)`, 'gi');
  let hash;

  if (re.test(url)) {
    if (typeof value !== 'undefined' && value !== null) {
      return url.replace(re, `$1${key}=${value}$2$3`);
    }
    hash = url.split('#');
    url = hash[0].replace(re, '$1$3').replace(/(&|\?)$/, '');
    if (typeof hash[1] !== 'undefined' && hash[1] !== null) {
      url += `#${hash[1]}`;
    }
    return url;
  }
  if (typeof value !== 'undefined' && value !== null) {
    const separator = url.indexOf('?') !== -1 ? '&' : '?';
    hash = url.split('#');
    url = `${hash[0] + separator + key}=${value}`;
    if (typeof hash[1] !== 'undefined' && hash[1] !== null) {
      url += `#${hash[1]}`;
    }
    return url;
  }
  return url;
}

/**
 *
 * @param {string} url
 * @param {string[]} list
 * @throws {TypeError}
 */
function findUrlInList(url, list) {
  if (!list || !Array.isArray(list)) {
    throw new TypeError(`Array is invalid for url lookup: ${url}`);
  }
  return list.reduce(
    (found, strToFind) => found || RegExp(strToFind).test(url),
    false,
  );
}

function removeProtocol(url) {
  return url.replace(/(^\w+:|^)\/\//, '');
}

/**
 * Match on url without params or hash
 * IE 11 and FF <53 issues
 * @type {string}
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Location/pathname
 */
function getCurrentUrl() {
  if (
    location.hostname.indexOf('localhost') > -1 ||
    location.hostname.indexOf('local.hubspot.com') > -1
  ) {
    return `www.hubspot.com${location.pathname}`;
  }

  return location.hostname + location.pathname;
}

/**
 *
 * @param {string} url
 * @param {string} param
 * @returns {boolean}
 */
function isMissingUrlParam(url, param) {
  return url.indexOf(`${param}=`) === -1;
}

/**
 * @function filterProductParams
 * @memberof UrlParamPassthru
 * @param {string[]} paramsList a list of url params
 * @see getQueryParamsList
 */
function filterProductParams(paramsList) {
  // ⚠️ do not propagate these url params
  const inAppKeys = [
    '_health_check_',
    '_preview',
    'benderPackage',
    'cacheBurst',
    'cacheBust',
    'cssPath',
    'inpageEditorUI',
    'isPreview',
    'portalId',
    'preview_key',
    'preview_theme',
    'scriptPath',
    'staticVersion',
    'tc_deviceCategory',
    'term', // used by developers.hubspot.com
  ];
  const cmsDeveloperKeys = ['hs_preview', 'hsDebug', 'page'];
  // filter params keys - exclude keys found in ignoreKeys array
  function isParamInIgnoreList(param) {
    return ignoreKeys.indexOf(param.split('=')[0]) === -1;
  }
  const ignoreKeys = [...cmsDeveloperKeys, ...inAppKeys];
  return paramsList.filter(isParamInIgnoreList);
}

const isOverrideException = (elementId, param, exceptions = []) =>
  exceptions?.some(
    exception =>
      exception?.elementId === elementId &&
      exception?.pagePath === window?.location?.pathname &&
      exception?.params?.includes(param),
  );

const getCurrentValueFromUrl = (url, param) => {
  const params = url?.split('?')?.[1];

  const urlSearchParams = new URLSearchParams(params);
  return urlSearchParams.get(param);
};

export {
  addParamsToUrl,
  getCurrentUrl,
  findUrlInList,
  htmlCollectionToArray,
  isMissingUrlParam,
  mapHTMLCollection,
  removeProtocol,
  filterProductParams,
  isOverrideException,
  getCurrentValueFromUrl,
};
