/**
 * @author Matt Staff <mstaff@hubspot.com>
 * @copyright HubSpot, Inc (c) 2021
 */
import { getIsInDomainList } from './hubs-params-config';

import { filterProductParams } from './utils';

import {
  addParamsToUrl,
  getCurrentUrl,
  isMissingUrlParam,
  // mapHTMLCollection,
  removeProtocol,
} from './utils';

function updateFormPostUrlParam(url, param, referrer) {
  if (!isMissingUrlParam(url, param)) {
    return url;
  }
  return addParamsToUrl(url, param, referrer);
}

function updateFormUrlParam(url, param) {
  if (!isMissingUrlParam(url, param)) {
    return url;
  }
  const signupUrl = removeProtocol(getCurrentUrl().split('?')[0]);
  return addParamsToUrl(url, param, signupUrl);
}

function updateFormCtaParam(url, param, formId) {
  if (!isMissingUrlParam(url, param)) {
    return url;
  }
  return addParamsToUrl(url, param, formId);
}

/**
 *
 * @param {string} url a.href from Anchor element for updating
 * @param {string} formId id from cms form
 */
function addUrlParams(url, formId) {
  // Add hubs-signup params
  const signupUrlParam = 'hubs_signup-url';
  const signupCtaParam = 'hubs_signup-cta';
  const offerParam = 'hubs_offer';
  const postUrlParam = 'hubs_post';
  const postCtaParam = 'hubs_post-cta';
  const currentParamsList = window.location.search.slice(1).split('&');
  const filteredParams = filterProductParams(currentParamsList);
  const currentParams = '?' + filteredParams.join('&');

  // @todo get postCtaParam from currentParams
  const postCtaParamValue = undefined;

  const { inSignupDomain, inOffersDomain } = getIsInDomainList();
  const baseUrl = url.split('?')[0];
  let newUrl = baseUrl + currentParams;

  // check for thank you video on redirect url
  const isThankYouVideo = baseUrl.indexOf('/thank-you/video') !== -1;
  const isThankYouDemo = baseUrl.indexOf('/demo') !== -1;
  const isBRThankYouDemo = baseUrl.search(/ofertas\/obrigado$/) !== -1;
  const nextReferrerIsDemo =
    isThankYouVideo || isThankYouDemo || isBRThankYouDemo;

  if (inOffersDomain && !nextReferrerIsDemo) {
    newUrl = updateFormUrlParam(newUrl, offerParam);

    const referrer = removeProtocol(document.referrer);
    if (
      ~referrer.indexOf('blog.hubspot') ||
      ~referrer.indexOf('br.hubspot.com/blog')
    ) {
      newUrl = updateFormPostUrlParam(newUrl, postUrlParam, referrer);
      if (postCtaParamValue) {
        newUrl = updateFormCtaParam(newUrl, postCtaParam, postCtaParamValue);
      }
    } else {
      newUrl = updateFormUrlParam(newUrl, signupUrlParam);
      newUrl = updateFormCtaParam(newUrl, signupCtaParam, formId);
    }
  }

  if (inSignupDomain && nextReferrerIsDemo) {
    newUrl = updateFormUrlParam(newUrl, signupUrlParam);
    newUrl = updateFormCtaParam(newUrl, signupCtaParam, formId);
  }

  return newUrl;
}

/**
 * Add params and form id
 * @param {*} formElement
 * @param {*} options
 */
function updateForm(formElement, options) {
  if (!options || !options.redirectUrl) {
    return null;
  }
  options.redirectUrl = addUrlParams(options.redirectUrl, options.formId);

  if (!formElement || !formElement.remove) return null;
  // Remove form from DOM
  formElement.remove();
  // Recreate Form
  window.hbspt.forms.create(options);
}

function getFormContext(formElement) {
  var node = formElement.querySelector('input[name="hs_context"]');
  var contextInputValue = node && node.value;
  if (!contextInputValue) return false;
  try {
    const hsContext = JSON.parse(contextInputValue);
    return hsContext;
  } catch (error) {
    console.log('SyntaxError', error);
    return null;
  }
}

function validateForm(formElement) {
  if (!formElement) return null;
  const context = getFormContext(formElement);
  if (!context) return null;
  const options = context.originalEmbedContext;
  if (!options) throw new RangeError('Form Missing originalEmbedContext');
  // override any form styles
  options.css = '';
  delete options.privateInternalContext;
  delete options.hutk;

  if (!context || !options) return false;

  updateForm(formElement, options);
}

function handleForms() {
  // Handle Live HTML Collection in reverse order
  const forms = document.forms;
  const len = forms.length;
  for (let i = len - 1; i >= 0; i--) {
    const form = forms[i];
    validateForm(form);
  }
}

/**
 *
 */
function mainFormRedirect() {
  /**
   *  Observer Approach
   */
  // function observeCTAWrappers(wrapperId) {
  //   const targetNode = document.getElementById(wrapperId);
  //   if (MutationObserver) {
  //     const observer = new MutationObserver(handleCTAs);
  //     observer.observe(targetNode, { childList: true });
  //   }
  // }
  // function initObserver() {
  //   const nodes = document.querySelectorAll("[id^=hs_form_target]");
  //   const formIdList = mapHTMLCollection(nodes, el => el.id);
  //   formIdList.map(observeCTAWrappers);
  // }

  function init() {
    let retries = 0;
    const waitTime = 500;
    function waitForForms() {
      // Break after 5 seconds
      if (!document.forms.length) {
        if (retries > 10) return;
        setTimeout(waitForForms, waitTime);
        retries++;
      } else {
        // Loop & update over forms
        setTimeout(handleForms, waitTime);
      }
    }
    setTimeout(waitForForms, waitTime);
  }

  init();
}

export default mainFormRedirect;
