/**
 * @author Matt Staff <mstaff@hubspot.com>
 * @copyright HubSpot, Inc (c) 2021
 */
import appendPageQueryParams from './page-query-params.js';
import appendHubsParams from './hubs-params.js';
import updateFormRedirectUrls from './form-redirect.js';
import appendTrackingParams from './append-tracking-params.js';
import { name, version } from '../package.json';

export default function main() {
  // ⚠️ Abort code from running in HubSpot App / Preview iFrame
  if (
    window.hsInEditor ||
    window.location.hostname.indexOf('preview.hs-sites.com') > -1
  ) {
    return;
  }
  try {
    appendPageQueryParams();
    appendHubsParams();
    updateFormRedirectUrls();
    appendTrackingParams('data-navigation-params');
  } catch (e) {
    console.error(`Caught ${name}@${version} Error:`, e);
  }
}
